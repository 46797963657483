body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.css-cveggr-MuiListItemIcon-root,
.css-1f8bwsm {
  min-width: 40px !important;
}

.displayGride {
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  grid-template-columns: auto auto;
}
@media screen and (max-width: 720px) {
  .displayGride {
    grid-template-columns: auto;
  }
}
@media screen and (min-width: 1024) {
  .displayGride {
    grid-template-columns: auto auto;
  }
}
@media screen and (min-width: 1025px) {
  .displayGride {
    grid-template-columns: auto auto auto;
  }
}
@media screen and (min-width: 1300px) {
  .displayGride {
    grid-template-columns: auto auto auto auto;
  }
}
